import React, {useEffect, useState, useContext, useMemo} from 'react';
import LineChart from "../../Charts/LineChart";
import Dropdown from "../../UI/Dropdown";
import { getCheckpointStatistic } from "../../../services/floods";
import { ICheckpointStatistic } from "../../../types/district";
import dayjs from 'dayjs';
import {AppDataContext} from "../../../contexts/AppDataContext";

interface IDistrictChartModal {
    id: number;
    latitude: number | null;
    longitude: number | null;
}

const DistrictChartModal: React.FC<IDistrictChartModal> = ({ id, latitude, longitude }) => {
    const { date } = useContext(AppDataContext);

    const allOptions = [
        { value: '7d', label: 'неделю, прогноз' },
        { value: '1', label: 'месяц' },
        { value: '3', label: 'квартал' },
        { value: '12', label: 'год' },
        { value: '60', label: '5 лет' },
        { value: '360', label: 'все время' },
    ];


    const datePicked = useMemo(() => {
        const before7Days = new Date(new Date().setDate(new Date().getDate() - 7));

        return date.getTime() <= before7Days.getTime()
    }, [date])

    const getSortedOptions = () => {
        if (datePicked) {
            return allOptions.slice(1);
        }
        return allOptions;
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [monthCount, setMonthCount] = useState<{ value: string, label: string }>(getSortedOptions()[0]);
    const [statistic, setStatistic] = useState<ICheckpointStatistic[]>([]);

    useEffect(() => {
        fetchStatistic();
    }, [monthCount, date]);

    const getWaterLevelData = () => {
        let dates: string[] = [];
        let waterLevel: any[] = [];
        let waterLevelPerDay: any[] = [];
        let waterFlow: any[] = [];
        let forecastWaterLevel: any[] = [];
        let forecastWaterFlow: any[] = [];
        let weatherArr: any[] = [];
        const currentDate = new Date().getTime();

        statistic.forEach((item: ICheckpointStatistic) => {
            if (item.water_level_current === 0 && item.water_level_per_day === 0 && item.water_flow === 0) {
                return;
            } else {
                dates.push(dayjs(item.date).format("DD.MM.YYYY"));
                item.water_level_current === 0 ? waterLevel.push(null) : waterLevel.push(item.water_level_current);
                item.water_level_per_day ? waterLevelPerDay.push(null) : waterLevelPerDay.push(item.water_level_per_day);
                item.water_flow === 0 ? waterFlow.push(null) : waterFlow.push(item.water_flow);

                if (monthCount.value === "7d") {
                    forecastWaterLevel.push(item.water_level_current ?? item.forecast_water_level ?? null);
                    forecastWaterFlow.push(item.water_flow ?? item.forecast_water_flow ?? null);

                    if (item.temperature != null) {
                        if (new Date(item.date).getTime() > currentDate) {
                            weatherArr.push({ temperature: Math.round(item.temperature), description: item.weather_description });
                        }
                    }
                }
            }
        });

        return {
            chartData: {
                labels: dates,
                datasets: [
                    {
                        label: 'Уровень воды',
                        data: waterLevel,
                        borderColor: '#165BAA',
                        backgroundColor: '#165BAA',
                        yAxisID: "y"
                    },
                    {
                        label: 'Прогнозируемый уровень воды',
                        data: forecastWaterLevel,
                        borderColor: '#165BAA',
                        backgroundColor: '#165BAA',
                        borderDash: [8, 2],
                    },
                    {
                        label: 'Критический показатель',
                        data: [0],
                        borderColor: 'red',
                        backgroundColor: 'red'
                    },
                    {
                        label: 'Расход воды',
                        data: waterFlow,
                        borderColor: '#A155B9',
                        backgroundColor: '#A155B9',
                        yAxisID: "waterFlow"
                    },
                    {
                        label: 'Прогнозируемый расход воды',
                        data: forecastWaterFlow,
                        borderColor: '#A155B9',
                        backgroundColor: '#A155B9',
                        yAxisID: "waterFlow",
                        borderDash: [8, 2],
                    },
                ]
            },
            weather: weatherArr
        };
    };

    const fetchStatistic = async () => {
        setLoading(true);
        const formattedDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`
        const response = await getCheckpointStatistic(id, monthCount.value, datePicked ? formattedDate : undefined);
        if (!response.isError) {
            setStatistic(response.data);
            if (response.data.length < Number(monthCount.value)) {
                getSortedOptions().forEach((option) => {
                    if (Number(option.value) === response.data.length) setMonthCount(option);
                });
            }
        } else {
            setStatistic([]);
        }
        setLoading(false);
    };

    return (
        <div className="district-chart-modal">
            {loading ? (
                <div style={{
                    height: "65vh",
                    width: "1000px",
                    maxWidth: "80vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                    fontWeight: 600
                }}>
                    Загрузка...
                </div>
            ) : (
                <>
                    <div className="district-chart-modal-period">
                        <div className="district-chart-modal-period-title">Данные за</div>
                        <Dropdown
                            options={getSortedOptions()}
                            onSelect={(op) => setMonthCount(op)}
                            selectedOpt={monthCount}
                        />
                    </div>
                    <div className="district-chart-modal-item">
                        <div className="district-chart-modal-item-title">Уровень воды</div>
                        <div className="district-chart-modal-item-chart">
                            <LineChart
                                data={getWaterLevelData().chartData}
                                chartWidth={1000}
                                chartHeight={400}
                                isLegend={false}
                                hidePoints
                                criticalLevel={statistic.length !== 0 ? statistic[0].water_level_critical : 0}
                                secondAxis
                                hasPrediction={monthCount.value === "7d"}
                                weatherData={getWaterLevelData().weather}
                            />
                        </div>
                    </div>

                    {/* <div className="table-border">
                        <table className="table">
                            <thead className="table-header">
                                {tableHeader.map((item, index) =>
                                    <td key={index} className='cell'>
                                        {item}
                                    </td>
                                )}
                            </thead>

                            <tbody>
                                {statistic.map((item, index) =>
                                    <tr key={index} className="row">
                                        <td className="cell">{dayjs(item.date).format("DD.MM.YYYY")}</td>
                                        <td className="cell">{item.water_level_critical}</td>
                                        <td className="cell">{item.water_level_current.toFixed(2)} ({item.water_level_per_day.toFixed(2)})</td>
                                        <td className="cell">{item.water_flow.toFixed(2)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div> */}
                    <div className="district-chart-modal-legend">
                        <div className="district-chart-modal-legend-item">
                            <span className="district-chart-modal-legend-color waterLevel" />
                            Уровень воды
                        </div>
                        <div className="district-chart-modal-legend-item">
                            <div className="district-chart-modal-legend-color criticalLevel" style={{width: "32px"}}>
                                <span className="criticalLevel-line"/>
                                <span className="criticalLevel-line"/>
                                <span className="criticalLevel-line"/>
                            </div>
                            Критический показатель
                        </div>
                        <div className="district-chart-modal-legend-item">
                            <span className="district-chart-modal-legend-color waterFlow" />
                            Расход воды
                        </div>
                        <div className="district-chart-modal-legend-item">
                            <span className="criticalLevel-line predictedwaterLevel" />
                            <span className="criticalLevel-line predictedwaterLevel"/>
                            <span className="criticalLevel-line predictedwaterLevel"/>
                            Прогнозируемый уровень воды
                        </div>
                        <div className="district-chart-modal-legend-item">
                            <span className="criticalLevel-line predictedwaterFlow" />
                            <span className="criticalLevel-line predictedwaterFlow"/>
                            <span className="criticalLevel-line predictedwaterFlow"/>
                            Прогнозируемый расход воды
                        </div>
                    </div>
                    <p className="district-chart-modal-item-title">Расположение гидропоста</p>
                    <div className="district-chart-modal-iframe">
                        <iframe src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3081.0651724358404!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDQ4JzI5LjUiTiA4MsKwMjQnNDcuMCJF!5e1!3m2!1sru!2skz!4v1725963875703!5m2!1sru!2skz`} width="100%" height="450" style={{border: "0"}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </>
            )}
        </div>
    );
};

export default DistrictChartModal;