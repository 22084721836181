import React, {useContext, useEffect, useState} from 'react';
import {Arrow, ConstructionIcon } from "../Icons";
import {useLocation} from "react-router-dom";
import {AppDataContext} from "../../contexts/AppDataContext";
import {getFiresStatistic} from "../../services/fires";
import {IFiresStatistic} from "../../types/fires";
import dayjs from "dayjs";
import {IEngineeringWorksStatistic} from "../../types/engineering-works";
import {getSumString} from "../../utils";
import { getEngineeringWorksStatistic } from '../../services/engineering';

interface ISideInfoStatistic {
    handleShow: () => void;
}

const SideInfoStatistic: React.FC<ISideInfoStatistic> = ({handleShow}) => {
    const location = useLocation();
    const isFirePage = location.pathname.includes("fires");
    const {districts, date} = useContext(AppDataContext);
    const [isShow, setIsShow] = useState<boolean>(true);
    const [firesStatistic, setFiresStatistic] = useState<IFiresStatistic | null>(null);
    const [engineeringWorksStatistic, setEngineeringWorksStatistic] = useState<IEngineeringWorksStatistic>({
        cost_amount: 0,
        works_cnt: 0,
    });
    // const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isFirePage) {
            fetchFiresStatistic();
        } else {
            fetchEngineeringWorksStatistic();
        }
    }, [date]);

    const fetchEngineeringWorksStatistic = async () => {
        const response = await getEngineeringWorksStatistic()

        if (!response.isError) {
            setEngineeringWorksStatistic({
                works_cnt: response.data.works_cnt,
                cost_amount: response.data.cost_amount,
            });
        }
    }

    const getWaterLevelStatistic = () => {
        let critical = 0;
        let medium = 0;
        let normal = 0;

        districts.forEach((district) => {
            district.hydrometeorological_reports.forEach((point) => {
                if (point.water_level_status.toLowerCase() === "high") critical++;
                if (point.water_level_status.toLowerCase() === "medium") medium++;
                if (point.water_level_status.toLowerCase() === "normal") normal++;
            });
        });

        return {
            critical: critical,
            medium: medium,
            normal: normal
        }
    };


    const fetchFiresStatistic = async () => {
        const response = await getFiresStatistic(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setFiresStatistic(response.data);
        } else {
            setFiresStatistic(null);
        }
    };
    return (
        <div className="side-info-card side-info-statistic" id="side-info-statistic">
            <div className="side-info-statistic-title">ОБЩАЯ СТАТИСТИКА</div>
            <div className="data-list">
            </div>
            <div className="side-info-statistic-data">
                {isFirePage ? (
                    <div className="data-list">
                        <div className="data-list-item">
                            <div>Количество пожаров</div>
                            <div>{firesStatistic?.total_fires}</div>
                        </div>
                        <ul>
                            <li>
                                <div className="data-list-item">
                                    <div>Лесные</div>
                                    <div>{firesStatistic?.forest_fires}</div>
                                </div>
                            </li>
                            <li>
                                <div className="data-list-item">
                                    <div>Степные</div>
                                    <div>{firesStatistic?.steppe_fires}</div>
                                </div>
                            </li>
                            <li>
                                <div className="data-list-item">
                                    <div>Домашние</div>
                                    <div>{firesStatistic?.domestic_fires}</div>
                                </div>
                            </li>
                        </ul>
                        <div className="data-list-item">
                            <div>Действующие пожары</div>
                            <div>{firesStatistic?.active_fires}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Устраненные пожары</div>
                            <div>{firesStatistic?.extinguished_fires}</div>
                        </div>
                    </div>
                ) : (
                    <div className="floods-statistics">
                        <div className="data-list">
                            <div className="data-list-item">
                                <div>Нормальная ситуация</div>
                                <div>{getWaterLevelStatistic().normal}</div>
                            </div>
                            <div className="data-list-item">
                                <div>Стабильная ситуация</div>
                                <div>{getWaterLevelStatistic().medium}</div>
                            </div>
                            <div className="data-list-item">
                                <div>Критическая ситуация</div>
                                <div>{getWaterLevelStatistic().critical}</div>
                            </div>
                        </div>

                        <div className="floods-statistics-engineering-works">
                            <div>
                                <ConstructionIcon size={40} />
                            </div>

                            <div className="floods-statistics-engineering-works-data">
                                <div>Инженерные работы: <span>{(engineeringWorksStatistic?.works_cnt || 0) + " Проектов"}</span></div>
                                <div style={{ fontWeight: "bold" }}>{getSumString(Number(engineeringWorksStatistic?.cost_amount || 0))+" тенге"}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div
                className={isShow ? "side-info-card hide-button" : "side-info-card hide-button open"}
                onClick={() => {
                    setIsShow(!isShow);
                    handleShow();
                }}
            >
                <Arrow />
            </div>
        </div>
    );
};

export default SideInfoStatistic;