import React from "react"

const RightSideLegends: React.FC = () => {
    return (
        <div className="floods-side-legends">
            <div className="side-info-big-card" style={{width: "fit-content"}}>
                <div className="side-info-legend">
                    <p>Уровень воды (мм)</p>
                    <div className="side-info-legend-item">
                        <div className="marker" />
                        <p> - Нормальная</p>
                    </div>
                    <div className="side-info-legend-item">
                        <div className="marker medium" />
                        <p> - Стабильная</p>
                    </div>
                    <div className="side-info-legend-item">
                        <div className="marker high" />
                        <p> - Критичная</p>
                    </div>
                    <div className="side-info-legend-item">
                        <div className="marker special" />
                        <p> - Нет данных</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RightSideLegends;