import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SideInfoStatistic from "./SideInfoStatistic";
import {AppDataContext} from "../../contexts/AppDataContext";
import SideInfoRegionItem from "./SideInfoRegionItem";
import {Arrow, ConstructionIcon} from "../Icons";
import SideInfoDistrictItem from "./SideInfoDistrictItem";
import {getDistrictNameById} from "../../utils";
import {IFire} from "../../types/fires";
import {EngineeringInfoCard} from "../FloodsPages/DistrictPage/EngineeringInfoCard";
import EngineeringWorksModal from "../FloodsPages/DistrictPage/modal/EngineeringModal";

interface ISideInfo {
    allFires?: IFire[]
}

const SideInfo: React.FC<ISideInfo> = ({allFires}) => {
    const params = useParams();
    const districtId = params.id;
    const navigate = useNavigate();
    const location = useLocation();
    const isFirePage = location.pathname.includes("fires");
    const {districts, district, setSelectedPoint} = useContext(AppDataContext);
    const [isShow, setIsShow] = useState<boolean>(true);
    const [listHeight, setListHeight] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const total_works = 5;
    const total_cost = "3,500,000";
    useEffect(() => {
        if (visualViewport) {
            visualViewport.addEventListener('resize', () => {
                calculateDistrictsListSize();
            });
        }
        calculateDistrictsListSize();

        setSelectedPoint(null);
    }, []);

    const calculateDistrictsListSize = () => {
        const pageHeight = window.innerHeight;
        if (districtId) {
            const districtName = document.getElementById("side-info-district-name");
            if (districtName) {
                const districtNameHeight = districtName.offsetHeight;
                setListHeight(pageHeight - districtNameHeight - 114);
            }
        } else {
            const statistic = document.getElementById("side-info-statistic");
            if (statistic) {
                const statisticHeight = statistic.offsetHeight;
                setListHeight(pageHeight - statisticHeight - 114);
            }
        }
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={isShow ? "side-info" : "side-info side-info-hide"} style={location.pathname.includes("/district/") ? {maxWidth: "349px"} : {}}>
            {districtId ? (
                <>
                    <div className="side-info-district" id="side-info-district-name">
                        <div
                            className={isFirePage ? "side-info-card back-btn" : "side-info-card back-btn floods-back-btn"}
                            onClick={() => navigate(isFirePage ? "/fires" : "/")}
                        >
                            <Arrow />
                        </div>
                        <div className="side-info-card side-info-district-name">
                            {getDistrictNameById(Number(districtId))}
                        </div>
                    </div>
                    <div className="side-info-items" style={{maxHeight: listHeight}}>
                        {isFirePage ?
                            allFires?.map((fire) =>
                                <SideInfoDistrictItem key={fire.id} fire={fire} />
                            )
                        :
                            <>
                                <EngineeringInfoCard />

                                {district?.hydrometeorological_reports && district.hydrometeorological_reports.map((point, index) =>
                                    <SideInfoDistrictItem key={point.id} data={point} count={index + 1} onClick={() => setSelectedPoint(point)}/>
                                )}
                            </>
                        }
                    </div>
                    {!isFirePage &&
                        <div className="side-info-big-card" style={{width: "fit-content"}}>
                            <div className="side-info-legend">
                                <div className="side-info-legend-item">
                                    <div className="marker" style={{background: "#34A8E5"}}/>
                                    <p> - Уровень воды за последнюю неделю</p>
                                </div>
                                <div className="side-info-legend-item">
                                    <div className="marker" style={{background: "#076d30"}}/>
                                    <p> - Прогнозируемый уровень воды</p>
                                </div>
                                <div className="side-info-legend-item">
                                    <div className="marker" style={{background: "red"}}/>
                                    <p> - Критический уровень</p>
                                </div>
                            </div>
                        </div>
                    }
                </>
            ) : (
                <>
                    <SideInfoStatistic handleShow={() => setIsShow(!isShow)} />
                    <div className="side-info-items" style={{maxHeight: listHeight}}>
                        {districts.map((district) =>
                            <SideInfoRegionItem district={district} key={district.name} />
                        )}
                    </div>
                    {/* {!isFirePage &&
                        <div className="side-info-big-card" style={{width: "fit-content"}}>
                            <div className="side-info-legend">
                                <p>Уровень воды (мм)</p>
                                <div className="side-info-legend-item">
                                    <div className="marker" />
                                    <p> - Нормальная</p>
                                </div>
                                <div className="side-info-legend-item">
                                    <div className="marker medium" />
                                    <p> - Стабильная</p>
                                </div>
                                <div className="side-info-legend-item">
                                    <div className="marker high" />
                                    <p> - Критичная</p>
                                </div>
                                <div className="side-info-legend-item">
                                    <div className="marker special" />
                                    <p> - Нет данных</p>
                                </div>
                            </div>
                        </div>
                    } */}
                </>
            )}{isModalOpen && (
            <EngineeringWorksModal isOpen={isModalOpen} onClose={handleCloseModal} />
        )}
        </div>
    );
};

export default SideInfo;