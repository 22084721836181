import React, {useContext, useEffect, useState} from 'react';
import Map from "../../Map/Map";
import {getDistricts, getFloodProneAreas, getHydroNodes} from "../../../services/floods";
import {AppDataContext} from "../../../contexts/AppDataContext";
import dayjs from "dayjs";
import Filter from "../../UI/Filter";
import {GTS, HydraulicPost, Water} from "../../Icons";
import {IFilterOption} from "../../../types";
import SideInfo from "../../SideInfo/SideInfo";
import { ICheckpoint } from '../../../types/district';
import RightSideLegends from '../RIghtSideLegends';

export const floodsFilterOptions: IFilterOption[] = [
    {value: "0", label: "Гидропосты", icon: <HydraulicPost />},
    {value: "1", label: "Паводкоопасные участки", icon: <Water />},
    {value: "2", label: "Гидроузлы", icon: <GTS />},
];

const RegionPage = () => {
    const {districts, date, updateDistricts, updateDistrict} = useContext(AppDataContext);
    const [filter, setFilter] = useState<IFilterOption>(floodsFilterOptions[0]);
    const blueCheckpoints: ICheckpoint[] = [];

    useEffect(() => {
        loadDistrictsData();
        updateDistrict(null);
    }, [date]);

    useEffect(() => {
        loadHydroNodes();
        loadFloodProneAreas()
    }, [filter]);

    const loadDistrictsData = async (): Promise<void> => {
        const response = await getDistricts(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            updateDistricts(response.data);
        } else {
            updateDistricts([]);
        }
    };

    const loadFloodProneAreas = async (): Promise<void> => {
        const response = await getFloodProneAreas();
        // console.log(response)
        // if (!response.isError) {
        //     updateDistricts(response.data);
        // } else {
        //     updateDistricts([]);
        // }
    };

    const loadHydroNodes = async (): Promise<void> => {
        const response = await getHydroNodes();
        // console.log(response);
        // if (!response.isError) {
        //     console.log(response.data);
        // } else {
        //     updateDistricts([]);
        // }
    };

    if (districts) {
        for (let i = 0; i < (districts.length); i++) {
            for (let j = 0; j < (districts[i].checkpoints.length); j++) {
                if (!districts[i].hydrometeorological_reports.map((report) => report.checkpoint).includes(districts[i].checkpoints[j].id)) {
                    blueCheckpoints.push(districts[i].checkpoints[j]);
                }
            }
        }
    }

    return (
        <>
            <Map districts={districts} blueCheckpoints={blueCheckpoints}/>
            <SideInfo />
            {/* <Filter
                options={floodsFilterOptions}
                onSelect={(option) => setFilter(option)}
                selectedOpt={filter}
            /> */}

            <RightSideLegends />
        </>
    );
};

export default RegionPage;