import React, { useEffect, useState } from "react";
import { ConstructionIcon } from "../../Icons";
import { getSumString } from "../../../utils";
import { getEngineeringWorksStatistic } from "../../../services/engineering";
import { IEngineeringWorksStatistic } from "../../../types/engineering-works";
import { useParams } from "react-router-dom";
import EngineeringWorksModal from "./modal/EngineeringModal";


export const EngineeringInfoCard: React.FC = () => {
    const params = useParams();

    const [engineeringWorksStatistic, setEngineeringWorksStatistic] = useState<IEngineeringWorksStatistic>({
        cost_amount: 0,
        works_cnt: 0,
    });

    const [showModal, setShowModal] = useState(false);

    const fetchEngineeringWorksStatistic = async (id?: string | number) => {
        const response = await getEngineeringWorksStatistic(id);

        if (!response.isError) {
            setEngineeringWorksStatistic({
                works_cnt: response.data.works_cnt,
                cost_amount: response.data.cost_amount,
            });
        }
    }

    useEffect(() => {
        fetchEngineeringWorksStatistic(params.id || undefined);
    }, [params.id]);

    const handleClickMore = () => setShowModal(true);

    return (
        <>
            <div className="floods-district-engineering-card">
                <div className="floods-district-engineering-card-icon">
                    <ConstructionIcon size="32" />
                </div>

                <div className="floods-district-engineering-card-text">
                    <p>Инженерные работы: {engineeringWorksStatistic.works_cnt || 0}</p>
                    <p>Бюджет {getSumString(Number(engineeringWorksStatistic.cost_amount || 0))+" тенге"}</p>
                </div>

                <div className="floods-district-engineering-card-actions">
                    <button className="floods-district-engineering-card-actions-btn" onClick={handleClickMore}>
                        Подробно
                    </button>
                </div>
            </div>

            {
                showModal &&
                <EngineeringWorksModal isOpen={true} onClose={() => setShowModal(false)} />
            }
        </>
    );
};
