import { IEngineeringWorks, IEngineeringWorksStatistic } from "../types/engineering-works";
import axiosInstance from "./axiosInstance";
import { TResult } from "./types";

export const getEngineeringWorksStatistic = async (districtId?: string | number): Promise<TResult<IEngineeringWorksStatistic>> => {
    try {
        const response = await axiosInstance.get<IEngineeringWorksStatistic>(
            '/api/hydrometeorology/engineering-works/stats/', 
            { params: { district_id: districtId } },
        );

        return { isError: false, data: response.data };
    } catch (error: any) {
        console.error("Ошибка при получении данных об инженерных работах:", error);
        return { isError: true, error: error?.response?.data || error?.message };
    }
};

export const getEngineeringWorks = async (districtId: string | number): Promise<TResult<IEngineeringWorks[]>> => {
    try {
        const response = await axiosInstance.get<IEngineeringWorks[]>(
            '/api/hydrometeorology/engineering-works/', 
            {
                params: {district_id: districtId},
            },
        );
        
        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error?.response?.data || error?.message };
    }
}