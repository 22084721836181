export interface IEngineeringWorksStatistic {
    cost_amount: number | string,
    works_cnt: number | string,
}

export enum IEngineeringWorksStatus {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    DONT_START = "DONT_START",
}

export interface IEngineeringWorks {
    district_name: string,
    activity_text?: string,
    settlement?: string,
    regional_budget?: number,
    local_budget?: number,
    economy?: number,
    completion_date?: string,
    completion_percent?: number,
    status?: IEngineeringWorksStatus
}