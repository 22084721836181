import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal";
import { IEngineeringWorks, IEngineeringWorksStatus } from "../../../../types/engineering-works";
import { getEngineeringWorks } from "../../../../services/engineering";
import { useParams } from "react-router-dom";

interface EngineeringWorksModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ENGINEERING_WORKS_STATUS_NAMES: Record<IEngineeringWorksStatus, string> = {
    [IEngineeringWorksStatus.COMPLETED]: 'Завершено',
    [IEngineeringWorksStatus.DONT_START]: 'Не начато',
    [IEngineeringWorksStatus.IN_PROGRESS]: 'В работе',
}

const EngineeringWorksModal: React.FC<EngineeringWorksModalProps> = ({ isOpen, onClose }) => {
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [engineeringWorkDetails, setEngineeringWorkDetails] = useState<IEngineeringWorks[]>([]);

    const fetchEngineeringWorkDetails = async () => {
        setLoading(true);

        const response = await getEngineeringWorks(String(params.id));

        if (!response.isError){
            setEngineeringWorkDetails(response.data);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchEngineeringWorkDetails();
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Инженерные работы"
            bigModal={true}
        >
            {loading ? (
                <h1>Загрузка...</h1>
            ) : (
                <div className="table-border tenders-table">
                    <table className="table">
                        <thead className="table-header">
                        <tr>
                            <th className="cell">#</th>
                            <th className="cell">Мероприятие</th>
                            <th className="cell">Населенный пункт</th>
                            <th className="cell">Областной бюджет</th>
                            <th className="cell">Местный бюджет</th>
                            <th className="cell">Экономия</th>
                            <th className="cell">Срок завершения работ</th>
                            <th className="cell">% выполнения</th>
                            <th className="cell">Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        {engineeringWorkDetails.map((detail, index) => (
                            <tr className="row" key={index}>
                                <td className="cell">{index + 1}</td>
                                <td className="cell">{detail.activity_text}</td>
                                <td className="cell">{ [detail.settlement, detail.district_name].filter(Boolean).join(" | ") }</td>
                                <td className="cell">{Number(detail.regional_budget || 0).toLocaleString("ru-RU")}</td>
                                <td className="cell">{Number(detail.local_budget || 0).toLocaleString("ru-RU")}</td>
                                <td className="cell">{detail.economy}</td>
                                <td className="cell">{detail.completion_date}</td>
                                <td className="cell">{detail.completion_percent}</td>
                                <td className="cell">{(detail.status && ENGINEERING_WORKS_STATUS_NAMES[detail.status])}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </Modal>
    );
};

export default EngineeringWorksModal;
